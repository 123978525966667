<template>
  <div class="invoice">
    <div class="title">我的发票</div>
    <div class="pipe">若您在交易过程中有开票需求，请完善下列开票信息
</div>
    <div class="pipe">请确保开票信息与公司营业执照信息完全一致，需要发票请在提交订单前勾选“需要开票”项，并核对或完善您的开票信息  
    </div>
    <div class="pipe pi-last">商家收到您的开票需求后，会将发票随货发出，请注意查收。  
    </div>
    <div class="titename">我的开票信息</div>
    <el-form ref="form" :model="form" label-width="140px">
      <el-form-item label="单位名称：">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="识别码：">
        <el-input v-model="form.number"></el-input>
      </el-form-item>
      <el-form-item label="注册地址：">
        <el-input v-model="form.address"></el-input>
      </el-form-item>
      <el-form-item label="注册电话：">
        <el-input v-model="form.telphone"></el-input>
      </el-form-item>
      <el-form-item label="开户银行：">
        <el-input v-model="form.brand"></el-input>
      </el-form-item>
      <el-form-item label="银行账户：">
        <el-input v-model="form.account"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

export default {
  name: 'invoice',
  data() {
    return {
      form: {
        name: '南京广而告之信息技术有限公司',
        number: '91320104MA1PCFEL9W',
        address: '南京市鼓楼区汉中路180号星汉大厦7楼C座',
        telphone: '025-86658725',
        brand: '交通银行南京汉中路支行',
        account: ' 320006609018010059347',
      }
    }
  },
  components: {},
  methods: {
    onSubmit() {
      console.log('submit!');
    }
  }
  
}
</script>

<style lang='scss'>
  @import "../../../assets/scss/common.scss";
  @import "../../../assets/scss/reset.scss";
  $theme-color: #0db168;
  .invoice{
    padding: 30px 20px;
     .title{
         font-size: 18px;
         color: #333333;
         padding-bottom: 25px;
     } 
     .pipe{
      font-size: 14px;
      color: #666666;
      margin-bottom: 6px;
     }
     .pi-last{
        margin-bottom: 50px;
      }
     .titename{
       border-left: 3px solid $theme-color;
       font-size: 16px;
       padding-left: 10px;
     }
     .el-form{
       margin: 28px 0;
       .el-form-item__content{
         min-width: 300px !important;
         float: left;
         margin-left: 0px !important;
         .el-button--primary{
           margin-left: 140px;
           color: #ffffff;
           background-color: $theme-color;
           border-color: $theme-color;
         }
       }
     }
  }
</style>
